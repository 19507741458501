import gwService from "../gwRequest"
export function addWxGzViewRecord(query) {//添加查看条件达标用户记录
    return gwService({
        method: "POST",
        url: "/wxGz/view/add",
        data: query,
        isNotToken:true
    });
}


