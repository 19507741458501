<template>
  <!--  <Transition name="el-zoom-in-top">-->
  <Transition name="el-zoom-in-top">
    <div  v-show="show" style="border: 1px solid #ECECEC;min-height: 100%;width: 100%;background-color:#F5F5F5;position: fixed;z-index: 18;">
      <div style="position: fixed;right: 15px;top: 18px;width: fit-content;z-index: 3000" @click="isNoShow">
        <el-image :src="require('../../../assets/image/whiteClose.png')" style="height: 15px"></el-image>
      </div>
      <div class="ChuiZhiJuZhong" style="width: 100%;height: 160px;background-color: #339161;padding: 36px 20px 20px 20px">
        <div style="width: 46%">
          <div style="font-size: 20px;color:white;">累计收入</div>
          <div style="font-size: 26px;color:white;margin-top: 30px;line-height: 30px;height: 30px" class="ChuiZhiJuZhong"><span style="font-size: 18px;">￥</span>{{accumulatedAmount}}</div>
        </div>
        <div style="height: 100px;border-left: 1px solid #8A8A8A;"></div>
        <div style="width: 46%">
          <div style="font-size: 20px;color:white;">绑定数量</div>
          <div style="font-size: 26px;color:white;margin-top: 30px;line-height: 30px;height: 30px" class="ChuiZhiJuZhong">{{shareRecord.count}}</div>
        </div>
      </div>
<!--      绑定列表-->
      <div>
        <div v-for="(item,index) in shareRecord.list" style="border-bottom:1px solid #EFEFEF ;background-color: white;border-radius: 16px;padding: 3px 6px" class="ChuiZhiJuZhong02">
<!--          头像及昵称-->
          <div class="ChuiZhiJuZhong02" style="height: 60px;width: 30%;font-size: 12px">
            <el-image style="margin-right: 6px;height: 45px;border-radius: 50%;width: 45px" :src="item.headerImage" fit="contain"></el-image>
            {{item.nickname}}
          </div>
<!--          日期-->
          <div style="flex-grow: 1;font-size: 14px">
            {{item.createDate}}
          </div>
<!--          金额、状态、操作-->
          <div class="ChuiZhiJuZhong02" style="width: 30%;margin-left: 6px;justify-content:right">
            <div class="ChuiZhiJuZhong02">{{item.amountOfMoney}}</div>
            <div @click="clickWithdrawalRetry(item)" class="ChuiZhiJuZhong02" :class="{successWaiKe:item.state===2,warnWaiKe:item.state===1,errWaiKe:item.state===3}" style="margin-left: 6px;border-radius: 3px;font-size: 13px;padding: 2px 5px">
              {{item.state===1?'转款中':item.state===2?'已成功':'重试'}}
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadingShow" class="ChuiZhiJuZhong" style="padding-top: 6px">
        <el-icon v-if="loadingIconShow" class="is-loading">
          <Loading />
        </el-icon>
        {{ loadingText }}
      </div>
      <div v-if="shareRecord.list.length===0" style="text-align: center;color: #999999;padding-top: 6px">
        暂无数据
      </div>
    </div>
  </Transition>
</template>
<script setup>
import {ref,onMounted} from "vue";
import {getBindingList} from "@/api/modules/share"
import {getTotal,withdrawalRetry} from "@/api/modules/wxGzQrCodeShareTransferOfFunds"
import {ElLoading,ElMessage} from "element-plus";
const loadingText= ref("加载中...");//下方加载文字
const loadingShow = ref(false);//下方加载是否显示开关
const loadingIconShow = ref(false);//下方加载图标是否显示
//控制当前页面显示隐藏
const show = ref(false);
//累计金额
const accumulatedAmount = ref(0.00)
const isNoShow = ()=>{
  show.value=!show.value
  if (show.value){
    //清空历史
    shareRecord.value={
      count:0,
      list:[]
    }
    //获取新数据
    getList()
  }else {
  }
}
//todo 提现重试
const clickWithdrawalRetry = (item)=>{
  if (item.state===3){
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    //重试
    withdrawalRetry({"shareRecordId":item.shareRecordId}).then(res=>{
      if (res.data.code===1001){
        ElMessage.success("操作成功")
        getList();
      }else {
        ElMessage.warning(res.data.msg)
      }
    }).catch(()=>[
      ElMessage.error("抱歉，服务暂不可用")
    ])
    loading.close();
  }

}
//todo 分页查询参数
const parameter = ref({
  pageIndex:1,
  pageSize:16,
})
//分页数据
const shareRecord = ref({
  count:0,
  list:[]
})
//todo 分页获取分享记录以及累计提现金额
const getList=async ()=>{
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  loadingText.value = "加载中..."
  loadingIconShow.value = true
  loadingShow.value = true
  //获取绑定记录，以及总人数
  await getBindingList(parameter.value).then(res=>{

    if (res.data.code===1001){
      shareRecord.value.list = shareRecord.value.list.concat(res.data.body.list)
      shareRecord.value.count = res.data.body.count
    }
  }).catch(()=>{
    ElMessage.error("服务暂不可用")
  })
  //获取累计提现金额
  await getTotal().then(res=>{
    if (res.data.code===1001){
      if (res.data.body!==null&&res.data.body!==undefined&&res.data.body!==''){
        accumulatedAmount.value = res.data.body.sum

      }
    }
  }).catch(()=>{
    ElMessage.error("服务暂不可用")
  })
  // loadingText.value = "加载中..."
  // loadingIconShow.value = true
  loadingShow.value = false
  loading.close();
}
defineExpose({isNoShow})

</script>
