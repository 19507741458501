<template>
  <Transition name="menu">

        <div @touchmove.prevent  @mousewheel.prevent v-show="show"  @click="isNoShow()" style="height: 100%;width: 100%;background:rgba(40,44,53,0.7);position: fixed;z-index: 18;top: 0">
          <div  @click.stop  class="constant" style="position: relative;background-color: #F5F5F5;border-radius: 10px">
            <div style="position: fixed;right: 10px;top: 10px;width: fit-content" @click="isNoShow">
              <el-image :src="require('../../../assets/image/close.png')" fit="contain" style="width: 15px"></el-image>
            </div>
            <slot>
              <!--     slot为插槽，接收父组件标签内填充的标签等内容-->
            </slot>
          </div>
        </div>
  </Transition>
<!--  //弹窗组件-->

</template>
<script setup>
import { ref,defineProps } from 'vue';
import {defineEmits} from 'vue'
const emit = defineEmits(['refresh'])
function clickChild(){
  emit('refresh')
}
//弹窗组件
//   const props = defineProps({
//     isNoShow: Boolean,
//   })
const show = ref(false)
const isNoShow = (boolean)=>{
  if (boolean===true||boolean===false){
    show.value = boolean;
  }else {
    show.value=!show.value
  }
}
//停止父组件页面滚动
const stopMove=()=>{
  let m = function(e){e.preventDefault();};
  document.body.style.overflow='hidden';
  document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
}
//开启父组件页面滚动
const Move=()=>{
  let m =function(e){e.preventDefault();};
  document.body.style.overflow='';//出现滚动条
  document.removeEventListener("touchmove",m,{ passive:true });
}
defineExpose({isNoShow})
</script>
<style>
  .constant{
    width: fit-content;
    background-color: white;
    padding: 30px 20px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  .menu-enter-from,.menu-leave-to{
    opacity: 0;
    transform: rotate(0turn);
  }
  .menu-enter-active,.menu-leave-active{
    transition: all 0.3s ease;
  }
</style>
