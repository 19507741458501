import gwService from "../gwRequest"
export function userIsNotPhoneAndGzOpenId(query) {//获取微信h5分享配置信息
    return gwService({
        method: "GET",
        url: "/user/sNotPhoneAndGzOpenId",
        data: query,
        isNotToken:true
    });
}


