<template>
  <div>
    <shareRecord ref="record"></shareRecord>
    <Transition name="menu">

      <div @touchmove.prevent @mousewheel.prevent v-show="show"  @click="isNoShow()" style="height: 100%;width: 100%;background:rgba(40,44,53,0.7);position: fixed;z-index: 17;">

        <div  @click.stop  class="constantShare" style="position: relative;" id="image">
          <div style="z-index: 9" :class="{pig01:!isNotPig,pig02:isNotPig}">
            <el-image :src="require('@/assets/image/share/halfPig.png')" style="height: 100px;"  fit="contain"></el-image>
          </div>
  <!--        <div style="z-index: 11;position: fixed;right: 10px;top: 10px;width: fit-content" @click="isNoShow">-->
  <!--          <el-image :src="require('../../../assets/image/close.png')" style="height: 15px"></el-image>-->
  <!--        </div>-->
          <div style="z-index: 10;position: relative;background-color: white; padding: 20px 10px;">

            <div style="margin: 10px auto auto;width: fit-content;font-size: 20px;font-weight: bold" class="ChuiZhiJuZhong">
              分享得<span style="color:rgb(192, 0, 0);" >现金</span>
              <div @click="isNoShowShare" style="width: fit-content;height: 20px;margin-left: 6px">
                <el-image :src="require('@/assets/image/wenHao.png')" style="height: 20px;"></el-image>
              </div>
            </div>
            <div style="margin-top: 20px" class="ChuiZhiJuZhong">
              <div class="li">
                无套路
                <div class="ChuiZhiJuZhong">
                  <el-image  :src="require('@/assets/image/share/duiGou.png')" fit="contain" style="height: 20px;"></el-image>
                </div>
              </div>
              <div class="li">
                纯宣传
                <div class="ChuiZhiJuZhong">
                  <el-image :src="require('@/assets/image/share/duiGou.png')" style="height: 20px;"></el-image>
                </div>
              </div>
              <div class="li">
                无审核
                <div class="ChuiZhiJuZhong">
                  <el-image :src="require('@/assets/image/share/duiGou.png')" style="height: 20px;"></el-image>
                </div>
              </div>
              <div class="li">
                秒到账
                <div class="ChuiZhiJuZhong">
                  <el-image :src="require('@/assets/image/share/duiGou.png')" style="height: 20px;"></el-image>
                </div>
              </div>
            </div>
            <div :class="{qrCode01:!imageIsNotShow,qrCode02:imageIsNotShow}" style="width: fit-content;margin: 20px auto auto">
              <el-image id="lll"  :class="{qrCode01:!imageIsNotShow,qrCode02:imageIsNotShow}" :src="wxGzQrCodeUrl" style="" fit="contain"></el-image>
  <!--            <el-image id="lll"  :class="{qrCode01:!imageIsNotShow,qrCode02:imageIsNotShow}" src="https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/%E7%8C%AA%E5%A4%B4logo.png" style="" fit="contain"></el-image>-->

  <!--            <img  :class="{qrCode01:!imageIsNotShow,qrCode02:imageIsNotShow}" :src="wxGzQrCodeUrl" style="" />-->

            </div>
            <div :class="{changEnBaoCun:imageIsNotShow,NotChangEnBaoCun:!imageIsNotShow}" style="line-height: 20px;text-align: center;color: #999999">
              由于限制，请长摁保存二维码
              <div @click="isNoShowRecord()" style="width: 260px;background-color: #339161;color: white;line-height: 30px;margin: 16px auto auto">
                查看记录
              </div>
            </div>
            <div :class="{GetQrCode:imageIsNotShow,NotGetQrCode:!imageIsNotShow}"  @click="getWxGzQrCode()" style="width: 260px;background-color: #339161;color: white;line-height: 36px;margin: 30px auto auto">
              获取二维码
            </div>
          </div>
        </div>

      </div>
    </Transition>
  </div>
</template>
<script setup>
import { ref,onMounted ,watch} from 'vue';
import {userGetShareQrCode} from "@/api/modules/share";
import {ElMessage,ElLoading} from "element-plus";
import {userIsNotPhoneAndGzOpenId,getQrUrl} from "@/api/modules/user";
import {defineEmits} from 'vue'
import shareRecord from '@/components/util/shareRecord/shareRecord.vue'

const emit = defineEmits(['refresh','quickLogin','showGuiZe'])
const wxGzQrCodeUrl = ref("");
//todo 二维码类型
const type = ref(1);
//todo 定义开关，控制猪得样式
const isNotPig = ref(false);

onMounted(()=>{
})
//切换规则弹窗状态
const isNoShowShare = ()=>{
  emit('showGuiZe')
}
const record = ref();
const isNoShowRecord = ()=>{
  record.value.isNoShow();
}
//todo 获取带参二维码
const getWxGzQrCode=async ()=>{
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  //先从缓存中获取
  let qrCodeUrl = localStorage.getItem("wxGzQrCodeUrl");
  if (qrCodeUrl===undefined||qrCodeUrl===null||qrCodeUrl===''){
    /**
     * 缓存中不存在，从服务端获取，首先检查用户是否存在openid，
     * 此操作主要是因为分享成功，需要使用用户openid向用户微信打款
     */
    let isNotPhoneAndGzOpenId = false
    await userIsNotPhoneAndGzOpenId().then(res=>{
      // ElMessage.warning(JSON.parse(JSON.stringify(res.data.body)).wxGzOpenid)
      //判断用户是否存在微信h5openid
      if (JSON.parse(JSON.stringify(res.data.body)).wxGzOpenid===true){

        isNotPhoneAndGzOpenId = true;
      }else {
        //没有openid，存储下次打开自动获取二维码指令,关闭当前窗口，重新用微信登录，补全用户信息
        isNoShow(false)
        emit('quickLogin');
        loading.close()
      }
    }).catch(err=>{
      loading.close();
      ElMessage.error("此功能暂不可使用。")
    })
    if (!isNotPhoneAndGzOpenId){
      //结束二维码获取，
      return;
    }
    //获取二维码
    userGetShareQrCode({"type":type.value}).then(res=>{
      if (res.data.code===1001){
        console.log(res.data.body)
        wxGzQrCodeUrl.value=res.data.body;
        //显示二维码
        imageIsNotShow.value = true;
        //将二维码url存入缓存
        localStorage.setItem("wxGzQrCodeUrl",res.data.body)
      }else {
        ElMessage.error(res.data.msg);
      }
      loading.close();
    }).catch(err=>{
      loading.close();
      ElMessage.error("此功能暂不可使用")
    })
  }else {
    wxGzQrCodeUrl.value = qrCodeUrl;
    loading.close();
  }
}
const show = ref(false)//页面显示开关
//todo 空值图片是否展示
const imageIsNotShow = ref(false);

//todo 显示
const isNoShow = (boolean)=>{
  if (boolean===true||boolean===false){
    show.value=boolean
  }else {
    show.value=!show.value
  }
  localStorage.removeItem("share");
  if (show.value){

    setTimeout(()=>{
      //让猪探出头
      isNotPig.value = true
      //获取二维码的地址
      let qrCodeUrl = localStorage.getItem("wxGzQrCodeUrl");
      if (qrCodeUrl!==null&&qrCodeUrl!==undefined&&qrCodeUrl!==''){
        console.log(qrCodeUrl)
        wxGzQrCodeUrl.value = qrCodeUrl
        //显示二维码
        imageIsNotShow.value = true;
      }

    },66)
    let zhiLing = localStorage.getItem("getQrCode");
    if (zhiLing==="exit"){
      //使用过后删除自动指令
      window.localStorage.removeItem('getQrCode')
      //存在自动获取指令，自动调用获取二维码指令
      getWxGzQrCode()
    }
    // stopMove()
  }else {
    //使用过自动弹出后，删除自动弹出指令
    isNotPig.value = false
    imageIsNotShow.value = false;
    // Move()
  }
}
//参与分享

//停止父组件页面滚动
const stopMove=()=>{
  let m = function(e){e.preventDefault();};
  document.body.style.overflow='hidden';
  document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
}
//开启父组件页面滚动
const Move=()=>{
  let m =function(e){e.preventDefault();};
  document.body.style.overflow='';//出现滚动条
  document.removeEventListener("touchmove",m,{ passive:true });
}
defineExpose({isNoShow})
</script>
<style>
.constantShare{
  width: fit-content;
  background-color: white;
  padding: 0;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.menu-enter-from,.menu-leave-to{
  opacity: 0;
  transform: rotate(0turn);
}
.menu-enter-active,.menu-leave-active{
  transition: all 0.3s ease;
}
.li{
  width: fit-content;padding: 0px 10px
}
.pig01{
  position: fixed;height: 100px;width: fit-content;
  top: -39px;
  left:50%;
  transform: translate(-50%);
  transition: top 0.3s ease;
}
.pig02{
  position: fixed;height: 100px;width: fit-content;
  top: -86px;
  left:50%;
  transform: translate(-50%);
  transition: top 0.6s ease;
}
.qrCode01{
  height: 0;
  transition: height 0.6s ease;
}

.qrCode02{
  height: 200px;
  transition: height 0.6s ease;
}
.changEnBaoCun{
  height: 66px;
  overflow: hidden;
  transition: height 0.5s ease;
}
.NotChangEnBaoCun{
  height: 0;
  overflow: hidden;

  transition: height 0.5s ease;
}
.GetQrCode{
  height: 0;
  overflow: hidden;

  transition: height 0.5s ease;
}
.NotGetQrCode{
  height: 36px;
  overflow: hidden;

  transition: height 0.5s ease;
}
</style>
