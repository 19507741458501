import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from "element-plus";
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ELIcons from "@element-plus/icons-vue";
import router from './router/index'
import mitt from 'mitt'
const app = createApp(App);

// import useClipboard from 'vue-clipboard3'

let orignialSetItem = window.localStorage.setItem;   // 原生localStorage.setItem方法
localStorage.setItem = function(key,newValue){
    let setItemEvent = new Event("setItemEvent");  // 重写注册setItem
    setItemEvent.key = key;
    setItemEvent.newValue = newValue;
    window.dispatchEvent(setItemEvent);            // 派发setItem
    orignialSetItem.apply(this, arguments);         // 设置值
}
import tool from "./api/tool.js";
// 小图标引用
for (const iconName in ELIcons) {
    // 注册组件
    app.component(iconName, ELIcons[iconName]);
}
app.config.globalProperties.$mitt = mitt();
app.use(ElementPlus, {
    locale: locale,
}).use(router).use(tool)
app.mount('#app')
