import gwService from "../gwRequest"

export function h5WxShareGw(query) {//获取微信h5分享配置信息
    return gwService({
        method: "POST",
        url: "/wxShare/h5/gw",
        data: query,
        isNotToken:false
    });
}

export function userGetShareQrCode(query) {//获取带参二维码
    return gwService({
        method: "POST",
        url: "/wxGz/qrCode/user/get",
        data: query,
        isNotToken:true
    });
}

export function getBindingList(query) {//分享绑定记录
    return gwService({
        method: "POST",
        url: "/wxGz/qrCode/get/share/list",
        data: query,
        isNotToken:true
    });
}


