<template>
  <div id="app" style="margin: 0 auto">
    <!--    顶部导航栏-->
    <!--    background-color: #007539;-->
    <div :class="{loginMode:mode!==1,notLoginMode:mode===1}" style="overflow: hidden;background: linear-gradient(#005328 ,#007539 30%);position: fixed;left: 0px;top: 0px;width: 100%;z-index: 11;padding:0px 15px;">
      <div style="height: 56px;overflow: hidden" class="ChuiZhiJuZhong">
        <div style="float: left;width: 120px;" class="ChuiZhiJuZhong" @click="search=!search"><el-image :src="require('./assets/image/langCaoChengShengTai.png')" style="width: 120px"></el-image></div>
        <div style="float: left; flex-grow: 1; " >

        </div>
        <div class="ChuiZhiJuZhong" :class="{loginModeIcon:mode===2,notLoginModeIcon:mode!==2}" style="height: 32px;float: left;width:fit-content;overflow: hidden;position: relative">
          <div @click="isNoShowSearch" style="margin-right: 17px" class="ChuiZhiJuZhong navigation-right">
            <el-image :src="require('./assets/image/search.png')" style="width: 20px"></el-image>
          </div>
          <!--          隐藏式导航栏-->
          <!--          <div @click="ifDD(ifDD)" class="ChuiZhiJuZhong navigation-right">-->
          <!--            <div style="width: 20px">-->
          <!--              <div :class="{line:true,line00:!dd,line01:dd}"></div>-->
          <!--              <div :class="{line:true,line10:!dd,line11:dd}"></div>-->
          <!--              <div :class="{line:true,line20:!dd,line21:dd}"></div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          点击头像跳转显示主页或者登陆界面-->


          <div @click="clickHeaderImage" style="width: 23px;height: 23px;overflow: hidden;border-radius: 50%" class="ChuiZhiJuZhong navigation-right">
            <el-image :src="headerImageUrl" style="width: 23px"></el-image>
          </div>
        </div>
      </div>
      <!--      横向滑动菜单栏-->
      <div v-if="mode!==3" :class="{loginMode:mode!==1,notLoginMode:mode===1}" id="horizontalNavigationBar" style="overflow-x: scroll;overflow-y: hidden;width: 100%;height: 43px;  scrollbar-width: none; /* Firefox */-ms-overflow-style: none; /* IE and Edge */">
        <div class="ChuiZhiJuZhong" style="width: fit-content;height: 33px">
          <div @click="tiaoZhuan(item.url)" :id="item.url" style="padding-bottom: 3px;font-size: 14px;width: fit-content; margin: 0 16px;white-space:nowrap;;color: white;float: left" v-for="item in menuList">
            {{item.text}}
            <div v-if="item.url===nowUrl" style="width: 70%;height: 3px;background-color: white;border-radius: 2px;margin: 3px auto auto"></div>
          </div>
        </div>

      </div>
      <!--      <div style="overflow-x: scroll;width: 100%;height: 33px;overflow-y: hidden;-->
      <!--			white-space:nowrap;">-->
      <!--        <div style="width: 200%;height: 200px;background-color: red">1111</div>-->

      <!--      </div>-->
    </div>
    <!--    联系我们-->
    <popUpNotification @refresh="shuaXin" ref="showComp">
      <div style="font-size: 13px;width: 300px;">
        <div style="font-size: 15px;font-weight: bolder;letter-spacing: 3px"><span style="color: red;">联系</span>我们</div>
        <div style="width: fit-content;margin: 30px auto;overflow: hidden" class="">
          <div  class="ChuiZhiJuZhong tanChuangPhoneStyle">
            <span style="height: 14px;padding-right: 5px" class="leftBorder"></span>{{'魏先生：'+phone01+' (微信同步) '}}<span class="copyStyle" @click="cope(phone01)">复制</span>
          </div>
          <div style="margin-top: 30px;"  class="ChuiZhiJuZhong tanChuangPhoneStyle">
            <span style="height: 14px;padding-right: 5px" class="leftBorder"></span>{{'魏先生：'+phone02+' (微信同步)'}}<span class="copyStyle" @click="cope(phone02)">复制</span>
          </div>

        </div>
      </div>
    </popUpNotification>
    <!--    搜索-->
    <search ref="search"></search>
    <!--    分享-->
    <share ref="share" @refresh="confirmQuickLogin" @showGuiZe="isNoShowGuiZe" @quickLogin="isNoShowQuickLogin('getQrCode','exit')"></share>
    <!--    信息登记-->
    <intentionOfChainStores  ref="intentionOfChainStores"></intentionOfChainStores>
    <!--    是否登录询问框-->
    <popUpNotification @refresh="shuaXin" ref="isNotLogin">
      <div style="font-size: 18px;width: 300px;">
        您好，此功能需要微信登陆
      </div>
      <div @click="confirmQuickLogin('share','exit')" style="width: 260px;height: 36px;background-color: #339161;color: white;line-height: 36px;margin: 30px auto auto">
        快捷登录
      </div>
    </popUpNotification>
    <popUpNotification @refresh="shuaXin" ref="isNotShortcutLogin">
      <div style="font-size: 18px;width: 300px;">
        您好，建议您先登录
      </div>
      <div @click="confirmShortcutLogin()" style="width: 260px;height: 36px;background-color: #339161;color: white;line-height: 36px;margin: 30px auto auto">
        快捷登录
      </div>
    </popUpNotification>
    <popUpNotification ref="loginGoOut" style="">
      <div style="font-size: 18px;width: 300px;">
        您确定要退出登陆吗？
      </div>
      <div class="ChuiZhiJuZhong">
        <div @click="isNoShowLoginGoOut(false)" style="width: 120px;height: 36px;background-color: white;line-height: 36px;margin: 30px 16px 0px 0px">
          取消
        </div>
        <div @click="loginOut" style="width: 120px;height: 36px;background-color: #339161;color: white;line-height: 36px;margin: 30px 16px 0px 0px">
          确定
        </div>
      </div>

    </popUpNotification>
    <!--    分享规则-->
    <popUpNotification ref="guiZe">
      <div style="width: 300px;font-size: 20px;font-weight: bold;text-align: center;color:#006330;">
        分享规则
      </div>
      <div ref="contentScroll" @touchmove.stop="listTouch" @mousewheel.stop="listTouch"   style="background-color: white;width: 300px;height: 360px;overflow: scroll;margin-top: 26px;">
        <div style="width: 300px;text-align: left;margin-top: 16px;text-indent: 16px">
          <span class="guiZeList">1</span>. 本活动因涉及到向您的微信转款，需要您进行微信登录授权，您所登录的微信号码即为收款微信，手机号码登录的用户暂时无法参与本活动；如需<span style="color: #925424;text-decoration: underline;" @click="qieHuanZhangHao()">更换账号</span>，前往首页右上角点击头像
        </div>
        <div style="width: 300px;text-align: left;margin-top: 16px;text-indent: 16px">
          <span class="guiZeList">2</span>. 本活动需要您获取并保存二维码进行分享，一个微信号码只能帮助一人分享成功，仅新用户生效
        </div>
        <div style="width: 300px;text-align: left;margin-top: 16px;text-indent: 16px">
          <span class="guiZeList">3</span>. 本活动奖励金额为随机数红包，每次分享成功，自动打款，无需手动提现
        </div>
        <div style="width: 300px;text-align: left;margin-top: 16px;text-indent: 16px">
          <span class="guiZeList">4</span>. 本活动分享成功达成条件：新用户进入页面进行<span style="color:#C00000;">微信授权登录</span>，浏览时间>=30秒并且浏览条数>=5条（仅包含导航栏标题；登录界面、弹窗界面除外）
        </div>
      </div>

    </popUpNotification>
    <!--    底部快捷-->
    <!--    <div  :class="{loginModeBottom:mode!==1,notLoginModeBottom:mode===1}" class="ChuiZhiJuZhong" style="font-weight: bold;width: 100%;height: 40px;background-color: #007539;color: white;position: fixed;left: 0;z-index: 10;border-top: 0.6px solid #EFEFEF;">-->
    <div  :class="{loginModeBottom:mode===2,notLoginModeBottom:mode===1,notLoginModeBottom3:mode===3}" :style="[{background:(mode===3?'rgba(0,117,57)':' linear-gradient(#007539 80%, #EDEDED )')}]" class="ChuiZhiJuZhong" style="font-weight: bold;width: 100%;color: white;position: fixed;left: 0;z-index: 10;border-top: 0.6px solid #EFEFEF;">

      <div v-if="mode!==3" style="width: 50%" @click="isNoShowIntentionOfChainStores()">连锁店信息登记</div>
      <!--      <div style="width: 50%" @click="tiaoZhuan(xiaContent.url)">{{ xiaContent.text+'⮕' }}</div>-->
      <div v-if="mode===3" class="ChuiZhiJuZhong" style="width: 100%">
        <div  v-for="(item,index) in partnerList"  style="width:45%">
          <div style="width: fit-content;margin: 0 auto auto" @click="tiaoZhuan(item.url,true)">
            <div style="width: fit-content;margin: 0 auto auto">
              <el-image :src="item.url===nowUrl?item.nowIcon:item.notNowIcon"  fit="contain" style="width: 18px;">
              </el-image>
            </div>
            <div style="font-size: 10px;margin: 0 auto auto">
              {{item.text}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--    侧边联系我们-->
    <div v-if="mode===1" style="opacity: 0.8;position: fixed;right: 6px;bottom: 156px;width: fit-content;z-index: 11;background:rgba(0,117,57,1);padding: 5px" class="ChuiZhiJuZhong" @click="isNoShowPhone()">
      <el-image :src="require('@/assets/image/phone.png')"  fit="contain" style="width: 30px;"></el-image>
    </div>
    <!--    侧边分享-->
<!--    <div v-if="mode===1" style="opacity: 0.8;position: fixed;right: 6px;bottom: 210px;width: fit-content;z-index: 11;background:rgba(0,117,57,1);padding: 5px" class="ChuiZhiJuZhong" @click="isNoShowShare()">-->
<!--      <el-image :src="require('@/assets/image/share.png')"  fit="contain" style="width: 30px"></el-image>-->
<!--    </div>-->
    <!--    菜单栏-->
    <Transition name="menu">
      <div v-show="dd" style="position: fixed;left: 0px;top: 56px;width: 100%;z-index: 11;background:rgba(255,255,255,1);border-radius: 10px;border-bottom: 1px solid #2B2B2B;">
        <div  @click="tiaoZhuan(item.url)" v-for="item in menuList" :class="{selected:item.url===nowUrl}" style="border-radius: 10px;width: 90%;margin:0px 12px;border-bottom: 0.5px solid #EFEFEF;padding: 12px 8px;text-align: left;color: black;opacity: 1;font-weight: bold;">
          {{ item.text }}
        </div>
      </div>
    </Transition>

    <!--    <img src="./assets/logo.png">-->
    <!--    @touchmove.prevent @mousewheel.prevent-->

    <router-view :style="[{paddingTop:mode===3?' 60px':' 106px'}]" /><!--    版权声明-->
    <div v-if="mode===1">
      <div style="text-align: center;margin-top: 36px;font-size: 12px;padding-bottom: 10px;color: #666" >
        Copyright © 2024 www.ccshengtai.top
      </div>
      <div style="text-align: center;font-size: 12px;padding-bottom: 70px;color: #666">
        河北省草承绿色食品有限公司

      </div>
    </div>


  </div>
</template>
<script>
import {RouterView} from "vue-router";
import {toRaw, onBeforeUnmount, ref} from "vue"
import router from "./router/index.js";
import useClipboard  from 'vue-clipboard3'
import popUpNotification from '@/components/util/popUpNotification/popUpNotification.vue'

import search from '@/components/util/search/search.vue'
import intentionOfChainStores from '@/components/intentionOfChainStores/intentionOfChainStores.vue'
import share from '@/components/util/share/share.vue'
import {addWxGzViewRecord} from "@/api/modules/wxGzViewRecord";
import {ElMessage} from "element-plus";
import {h5Http} from "@/api/gwRequest";
// 引入wxjs
import {h5WxShareGw} from "@/api/modules/share"
import {CircleCloseFilled} from '@element-plus/icons-vue'
export default {
  name: 'App',
  computed: {
    CircleCloseFilled() {
      return CircleCloseFilled
    }
  },
  components: {
    RouterView,
    popUpNotification,
    // spopUpNotificationearch,
    intentionOfChainStores,
    share,
    search
  },
  data(){
    return{
      headerImageUrl:"https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/login.png",//头像
      mode:1,//当前模式 1.浏览模式 2.登陆模式 3.合作商模式
      phoneShow:false,
      //菜单栏开关
      dd:false,
      //联系电话
      phone01:"18630285011",
      phone02:"17503181068",
      //菜单列表
      menuList:[
        {
          text:"公司简介",
          url:"/"
        },
        {
          text:"同类产品对比",
          url:"/contrast"
        },
        {
          text:"市场分析",
          url:"/marketAnalysis"
        },
        {
          text:"我们具备的条件",
          url:"/conditionsMet"
        },
        {
          text:"市场规划",
          url:"/marketPlanning"
        },
        {
          text:"为什么选择我们",
          url:"/reasonForSelection"
        },
        {
          text:"商学院",
          url:"/help"
        },
      ],
      partnerList:[
        {
          text:"出库",
          url:"/outbound",
          nowIcon:require("../src/assets/image/chuKu.png"),
          notNowIcon:require("../src/assets/image/chuKuWhite.png"),
        },
        {
          text:"签收",
          url:"/signFor",
          nowIcon:require("../src/assets/image/qianShou.png"),
          notNowIcon:require("../src/assets/image/qianShouWhite.png"),
        },
        // {
        //   text:"收入",//暂时不需要
        //   url:"/income",
        //   nowIcon:"",
        //   notNowIcon:require("../src/assets/image/chuKu.png"),
        // },
      ],
      nowUrl:"/",
      xiaContent:{},
      text:"",
      search:false,//搜索是否展示
      browsingDuration:0,//浏览时长
      browsingCount:[],//已浏览组件数组
      conditionTime:10,//达成条件的时间
    }
  },
  mounted(){

    let that = this;

    //监听缓存中login的变化
    window.addEventListener('setItemEvent', event => {
      event.stopPropagation();
      console.log(event.key)
      if(event.key === 'login') {
        that.shuaXin();
        setTimeout(()=>{
          //判断缓存中是否有待执行的指令
          that.instruct();
        },66)
      }else if (event.key==='intentionOfChainStores'){
        that.tiaoZhuan("/")

        that.isNoShowIntentionOfChainStores(true);
      }
    })
    let urlParameter = window.location.href .split("#")[1].split("?");
    //获取当前地址栏的路径#后当前组件路径
    this.nowUrl = urlParameter[0]
    this.accumulatedBrowsing(this.nowUrl)
    //更新显示
    this.renewView();


    //检测是否有重定向传来得用户code
    this.weiXinLogin();
    //微信网站分享配置设置
    this.fenXiang();
    //累积在线时长
    this.onlineDuration();
  },
  created() {

  },
  beforeUnmount() {

  },

  methods:{
    //todo 退出登录
    loginOut(){
      window.localStorage.removeItem("wxGzQrCodeUrl")
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("type");

      this.headerImageUrl = "https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/login.png";
      //跳转至首页
      this.tiaoZhuan("/")
      //刷新页面显示
      this.shuaXin();
      ElMessage.success("已退出登录")
      this.isNoShowLoginGoOut(false)
    },
    //快捷切换账号
    qieHuanZhangHao(){
      this.isNoShowGuiZe(false);
      this.isNoShowShare(false);
      this.loginOut()
      this.tiaoZhuan("/login")
    },
    //todo 滑动穿透
    listTouch(e) {
      //scrollHeight为滑动区内部内容的高度，
      //clientHeight为滑动区的高度
      //scrollHeight大于clientHeight允许滑动
      if (!(this.$refs.contentScroll.scrollHeight > this.$refs.contentScroll.clientHeight)) {
        e.preventDefault();
      }
    },
    //切换分享规则弹窗状态
    isNoShowGuiZe(){
      this.$refs.guiZe.isNoShow()
    },
    //todo 添加微信公众号浏览达标用户记录
    addWxGzViewUserRecord(){
      if (this.browsingCount.length>=5&&this.browsingDuration>=this.conditionTime){
        let token = localStorage.getItem("token")
        if (token!==undefined&&token!==""&&token!==null){
          addWxGzViewRecord().then(res=>{
            if (res.data.code===1001){
              this.isNoShowIntentionOfChainStores()
            }
          })
        }

      }

    },
    //todo 累计浏览组件
    accumulatedBrowsing(url){
      //遍历，添加已浏览路径
      for(let a=0;a<this.browsingCount.length;a++){
        if (this.browsingCount[a]===url||url==="/login"){
          //达到条件，添加记录到服务端
          if (url!=="/login"){
            this.addWxGzViewUserRecord()
          }
          //已添加，
          return;
        }
      }
      //添加
      this.browsingCount.push(url)
      //达到条件，添加记录到服务端
      this.addWxGzViewUserRecord()
    },
    //todo 累计在线时长
    onlineDuration(){
      setTimeout(()=>{
        this.browsingDuration+=1;
        this.addWxGzViewUserRecord()
        if (this.browsingDuration>=this.conditionTime){
          //达到条件，添加记录到服务端
          return
        }
        this.onlineDuration();
      },1000)
    },
    //todo 获取缓存中待执行指令
    instruct(){
      //todo 分享弹出
      let share = localStorage.getItem("share");
      if (share!==undefined&&share!==""&&share!==null){
        //判断是否已经登陆成功、登陆界面是否处于关闭状态
        let token = localStorage.getItem("token")
        let login = localStorage.getItem("login")

        if (token!==undefined&&token!==""&&token!==null&&login==="false"){
          this.isNoShowShare(true);
        }
      }
    },
    //todo 微信登录重定向信息检查
    weiXinLogin(){
      let search =  window.location.search.split('?')[1] || '';
      if (search) {
        //在H5页面获取code值
        let code = this.functiongetQueryString('code');
        if (code!==undefined&&code!==""){
          let url = h5Http+"/#/login?code="+code.split("#")[0]
          //跳转登陆页面
          window.location = url
        }

      }else {
        //显示快捷登陆弹窗提示
        let token = localStorage.getItem("token")
        let login = localStorage.getItem("login")
        if (login!=='true' &&(token===undefined||token===""||token===null)){
          this.isNotShowShortcutLogin();
        }
      }

    },
    //todo 分享配置
    fenXiang(){
      let windowurl = window.location.href.split('#')[0];
      let url = {
        "url":windowurl
      }

      h5WxShareGw(url).then(res =>{
        if(res.data.code===1001){
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.body.appId, // 必填，公众号的唯一标识
            timestamp: res.data.body.timeStamp, // 必填，生成签名的时间戳
            nonceStr: res.data.body.nonceStr, // 必填，生成签名的随机串
            signature: res.data.body.sign, // 必填，签名，见微信开发文档附录1
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage']
          });
          wx.ready(function() {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            //分享至微信好友
            wx.updateAppMessageShareData({
              title: '草承生态官网', // 分享标题
              desc: '河北省草承绿色食品有限公司，连锁店招商中...', // 分享描述
              link: 'https://www.ccshengtai.top/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: 'https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/%E7%8C%AA%E5%A4%B4logo.png', // 分享图标
              type: '', // 分享类型,music、video或link，不填默认为link
              dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
              success: function() {
                // 用户点击了分享后执行的回调函数
                // uni.showToast({
                // 	title: res.data.msg,
                // 	duration: 1000,
                // 	image:"../../static/login/chenggong.png",
                // 	complete(){

                // 	}
                // });
              }
            });
            //分享朋友圈
            wx.updateTimelineShareData({
              title: '草承生态官网', // 分享标题
              link: 'https://www.ccshengtai.top/', // 分享链接
              imgUrl: 'https://wei-cheng-img-list.oss-cn-beijing.aliyuncs.com/%E7%8C%AA%E5%A4%B4logo.png', // 分享图标
              success: function() {
                // 用户确认分享后执行的回调函数
              },
              cancel: function() {
                // 用户取消分享后执行的回调函数
              }
            });
          });
          wx.error(function(res) {
            //回滚
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            console.log('验证失败');
            console.log(res);
          });
        }
      })
    },

    functiongetQueryString(name){
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      const search =  window.location.search.split('?')[1] || '';
      const r = search.match(reg) || [];
      return r[2];
    },
    async shuaXin(){
      //获取当前地址栏的路径#后当前组件路径
      setTimeout(()=>{

      },100)
      this.nowUrl = window.location.href .split("#")[1].split("?")[0]

      this.renewView();

    },
    //点击头像跳转显示主页或者登陆界面
    clickHeaderImage(){
      if (localStorage.getItem('token')){
        //跳转个人主页
        // console.log("跳转个人主页")
        //询问是否要退出登录
        this.isNoShowLoginGoOut()
      }else {

        this.tiaoZhuan("/login");
      }
    },
    //横向导航栏自动滑动到相应位置
    automaticCorrection: function (url) {
      let target = document.getElementById(url);
      if (target === null) {
        return;
      }
      let parent = document.getElementById("horizontalNavigationBar");
      parent.scrollLeft = target.offsetLeft - parent.offsetLeft - (parent.offsetWidth - target.offsetWidth * 2)
    },
    //切换分享弹窗状态
    isNoShowLoginGoOut(boolean){
      this.$refs.loginGoOut.isNoShow(boolean)
    },
    //切换分享弹窗状态
    isNoShowShare(boolean){

      //检查是否已经登陆
      let token = localStorage.getItem("token");
      if (token===undefined||token===null ||token===""){
        //弹出快捷登录窗口
        this.isNoShowQuickLogin();
        return;
      }
      this.$refs.share.isNoShow(boolean)
    },
    //切换登记弹窗状态
    isNoShowIntentionOfChainStores(isNot){
      console.log("kkk")
      console.log(isNot)
      this.$refs.intentionOfChainStores.isNoShow(isNot);
    },
    //初次进入，登陆提示
    isNotShowShortcutLogin(){
      this.$refs.isNotShortcutLogin.isNoShow()
    },
    //切换是否快捷登录窗口状态
    isNoShowQuickLogin(key,value){
      if (key!==undefined&&key!==null&&key!==''){
        //如果存在key，存入缓存
        localStorage.setItem(key,value);
      }
      this.$refs.isNotLogin.isNoShow()
    },
    //确定快捷登录
    confirmQuickLogin(key,value){
      if (key!==undefined&&key!==null&&key!==''){
        //如果key不为空，存入自动指令
        localStorage.setItem(key,value);

      }
      //前往登录,加入微信登陆指令参数
      let url = h5Http+"/#/login?wxGzLogin="+"1"
      this.isNoShowQuickLogin()
      window.location = url
    },
    //确定初次进入快捷登录提示
    confirmShortcutLogin(){
      //前往登录,加入微信登陆指令参数
      let url = h5Http+"/#/login?wxGzLogin="+"1"
      this.isNotShowShortcutLogin()
      window.location = url
    },
    //切换搜索弹窗状态
    isNoShowSearch(){
      this.$refs.search.isNoShow()
    },
    //切换电话弹窗状态
    isNoShowPhone(){
      this.$refs.showComp.isNoShow()
    },
    //复制
    async cope(text){
      try {
        await useClipboard().toClipboard(text)
        this.$message.success('复制成功')
      } catch (e) {
        this.$message.error('复制失败')

      }
    },
    //
    phoneShowIs(){
      this.phoneShow=!this.phoneShow;
    },
    ifDD(){
      // this.nowUrl = toRaw(router).currentRoute.value.fullPath;

      this.dd = !this.dd;
    },
    //跳转页面
    async tiaoZhuan(url,notRenewView) {
      console.log(url);
      await router.push(url)
      //添加已浏览记录
      this.accumulatedBrowsing(url);
      // 更新下一篇按钮显示
      // this.xiaYiPian(url);
      //隐藏菜单栏
      // this.dd = false;
      //回到顶部
      document.documentElement.scrollTop = 0;
      //更新当前选择的路径
      this.nowUrl = toRaw(router).currentRoute.value.fullPath;
      if (!notRenewView) {
        //判断是否为更新显示方法调用，如果是，不在调用更新页面展示方法，避免死循环
        this.renewView();
      }
    },
    //更新页面展示
    renewView() {
      let token = localStorage.getItem("token")
      let login = localStorage.getItem("login")
      let type = localStorage.getItem("type");
      console.log(type)
      if (token !== undefined && token !== "" && token !== null && login === "false") {
        let headerImage = JSON.parse(localStorage.getItem("user")).headerImage;
        if (headerImage !== null && headerImage !== '' && headerImage !== undefined) {
          //修改展示头像
          this.headerImageUrl = headerImage;
        }
      }

      //更新上下导航栏显示
      if (this.nowUrl.split("?")[0] === '/login') {
        localStorage.setItem("type", 1)
        this.mode = 2;

      } else if (type == 2) {

        //合作商模式
        this.mode = 3;
        if (this.mode===3){
          //合作商模式
          this.tiaoZhuan(this.partnerList[0].url,true)
          console.log(this.nowUrl)
        }
      } else {
        console.log(this.mode)
        console.log(this.mode !== 1 && this.mode !== 2)
        localStorage.setItem("type", 1);

        if (type === null) {
          this.tiaoZhuan("/")
        }
        this.mode = 1;
        //不是跳转到登陆界面，更新导航栏显示
        this.automaticCorrection(this.nowUrl.split("?")[0])
      }
    }
    //更新app.vue上下导航栏显示内容

    //获取下一篇的名称和路由
    // xiaYiPian(url){
    //   console.log(url)
    //   for (let a=0;a<this.menuList.length;a++){
    //     //todo 判断当前页面所在索引
    //     if (this.menuList[a].url===url){
    //
    //       //todo 判断是否为最后一篇
    //       if (a===this.menuList.length-1){
    //         this.xiaContent = JSON.parse(JSON.stringify(this.menuList[0]));
    //         this.xiaContent.text = "返回首页"
    //       }else {
    //         this.xiaContent = this.menuList[a+1]
    //       }
    //     }
    //   }
    // }
  }
}
</script>
<style>

@import "./assets/css/global.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  background-color: #F5F5F5;
}

* {
  box-sizing: border-box;

}

.copyStyle {
  //color: white;
  margin-left: 20px;
  font-size: 12px;
  padding: 2px 15px;
  border: 1px solid #9999AA;
  //border-radius: 5px
}

.tanChuangPhoneStyle {
  width: fit-content;
}

.menu-enter-from, .menu-leave-to {
  opacity: 0;
  //transform: rotate(0turn);
}

.menu-enter-active, .menu-leave-active {
  transition: all 0.3s ease;
}


.line00 {
  transform: rotate(0turn);
  transition: transform 0.3s linear;
  transform-origin: bottom right;
}

.line01 {
  transform: rotate(-0.101turn);
  transition: transform 0.3s linear;
  transition-timing-function: cubic-bezier(0.1, 0.1, 0.1, 0.1);
  transform-origin: bottom right;

}

.line10 {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.line11 {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.line20 {
  transform: rotate(0turn);
  transition: transform 0.3s linear;
  transform-origin: top right;

}

.line21 {
  transform: rotate(0.101turn);
  transition: transform 0.3s linear;
  transition-timing-function: cubic-bezier(0.1, 0.1, 0.1, 0.1);
  transform-origin: top right;

}

.line {
  width: 20px;
  height: 2px;
  background-color: white;
  margin: 4.2px 0;
  border-radius: 2px;
}

.navigation-right {
  float: left;
  width: 20px;
  height: 32px;
  //padding: 0px 4px;
  //margin-left: 20px;
}

.selected {
  background-color: #E2E2E2;
}

.loginMode {
  height: 60px;
  transition: height 1s ease;
}

.notLoginMode {
  height: 99px;
  transition: height 1s ease;
}

.loginModeIcon {
  right: -23px;
  transition: right 1s ease;
}

.notLoginModeIcon {
  right: 0;
  transition: right 1s ease;
}

.loginModeBottom {
  bottom: -51px;
  height: 40px;
  transition: bottom 1s ease;
}

.notLoginModeBottom {
  bottom: -1px;
  height: 40px;

  transition: bottom 1s ease;
}

.notLoginModeBottom3 {
  bottom: -1px;
  height: 50px;

  transition: bottom 1s ease;
}

.guiZeList {
  color: rgb(192, 0, 0);
  font-weight: bolder;
  font-size: 19px
}
</style>
