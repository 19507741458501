import gwService from "../gwRequest"

export function gwGetList(query) {
    return gwService({
        method: "POST",
        url: "/gwOfficialWebsiteProduct/gwGetList",
        data: query,
        isNotToken:false
    });
}

export function gwGetCommodityInformation(query) {
    return gwService({
        method: "POST",
        url: "/gwOfficialWebsiteProduct/gwGetCommodity/information",
        data: query,
    });
}
