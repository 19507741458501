import service from "../request"

export function add(query) {
  return service({
    method: "POST",
    url: "/intentionOfChainStores/add",
    data: query,
    isNotToken:false
  });
}
