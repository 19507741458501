
<template>
  <div>
    <div  @touchmove.prevent  @mousewheel.prevent v-show="xiaDanState===2"  @click="qieHuanXiaDanState" style="height: 100%;width: 100%;background:rgba(40,44,53,0.7);position: fixed;z-index: 18;top: 0;left: 0">

    </div>
    <div style="transition: all 0.5s;z-index: 190;position: fixed;overflow: hidden" @click="xiaDanState===1?qieHuanXiaDanState():xiaDanState===2?'':qieHuanXiaDanState()"  :class="{xiaDanChuShi:xiaDanState===1}" :style="[{animation:xiaDanState===2?'xiaDan 0.2s steps(35) forwards':xiaDanState===3?'guanBiXiaDan 0.2s steps(35) forwards':''}]">
      <div style="text-align: center" v-if="xiaDanState===1||xiaDanState===3">
        {{ props.info }}
      </div>

      <div v-if="xiaDanState===2">
        <div @click.stop style="width: 100%;height: 100px;">
          <el-image :src="require('../../../assets/image/logoImg.png')" fit="contain" style="width: 100px;margin: 0px auto" mode = "aspectFit"></el-image>
        </div>
        <div v-if="xiaDanState===2" @click.stop style="margin-top: 0px;font-size: 24px;font-weight: bold;color: #318F5F">
          连锁店信息登记
        </div>
        <div class="inputWaiKe">
          <div class="inputTitle">客人姓名<span style="color: red">*</span> </div>
          <div class="inputValue">
            <el-input v-model="eIntentionOfChainStoresParameter.shopkeeperName" placeholder="请输入您的姓名" />
          </div>
        </div>
        <div class="inputWaiKe">
          <div class="inputTitle">手机号码<span style="color: red">*</span> </div>
          <div class="inputValue">
            <el-input v-model="eIntentionOfChainStoresParameter.phone" placeholder="请输入您的手机号" />
          </div>
        </div>
        <div class="inputWaiKe">
          <div class="inputTitle">微信号码</div>
          <div class="inputValue ChuiZhiJuZhong">
            <el-input style="flex-grow: 1" v-model="eIntentionOfChainStoresParameter.wxNumber" placeholder="请输入微信（选填）" />
            <div @click="samePhone" style="width:90px;color: white;padding: 8px 10px;margin-left: 3px;border-radius: 3px;background-color: #318F5F;font-size: 10px;text-align: center">同手机号</div>
          </div>
        </div>
        <div class="inputWaiKe">
          <div class="inputTitle">需求备注</div>
          <div class="inputValue">
            <el-input type="textarea" v-model="eIntentionOfChainStoresParameter.notes" placeholder="请填写需求量（选填）" />
          </div>
        </div>
        <el-button type="primary" @click="tiJiao()" style="margin: 20px auto;width: 40%">确认</el-button>
        <div style="text-align: center;font-size: 12px;color: #686B71;margin: 3px 20px">稍后会根据您的预留联系方式联系您，也可首页电话咨询</div>
      </div>
    </div>

  </div>

</template>

<script setup>
import {ref,defineProps } from "vue";
import {add} from "@/api/modules/intentionPlaceAnOrder"
import {
  ElLoading,
  ElMessage,
} from "element-plus";
//todo 表单信息
const  eIntentionOfChainStoresParameter=ref({
  shopkeeperName:"",
  phone:"",
  wxNumber:"",
  notes:"",
  gwOfficialWebsiteProductId:""
})
//todo 确认提交
const tiJiao = () =>{
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  //将父组件传来的当前产品id赋值给eIntentionOfChainStoresParameter
  eIntentionOfChainStoresParameter.value.gwOfficialWebsiteProductId=props.gwOfficialWebsiteProductId
  //执行添加
  add(eIntentionOfChainStoresParameter.value).then(res=>{
    if (res.data.code===1001){
      //成功
      ElMessage.success(res.data.msg);
      xiaDanState.value=1
    }else if(res.data.code===1002){
      ElMessage.info(res.data.msg);
    }
    loading.close()
  }).catch(()=>{
    loading.close()
  })
}
//todo 同手机号
const samePhone=()=>{
  if (eIntentionOfChainStoresParameter.value.phone!==null&&eIntentionOfChainStoresParameter.value.phone!==""){
    eIntentionOfChainStoresParameter.value.wxNumber= eIntentionOfChainStoresParameter.value.phone

  }
}
const props = defineProps({
  //按钮显示文字
  info: String,
  //商品id
  gwOfficialWebsiteProductId:Number
})
//todo 下单按钮状态 1.初始状态 2.填写个人信息 3.立即下单按钮
const xiaDanState = ref(1)
const qieHuanXiaDanState = () =>{
  if (xiaDanState.value===1){
    xiaDanState.value=2;
  }else if (xiaDanState.value===2){
    xiaDanState.value=3;

  }else {
    xiaDanState.value=2
  }
}
</script>

<style >
.xiaDanChuShi{
  width: 80%;background-color: #007539;border-radius: 20px;color: white;padding: 6px;font-weight: bold;left: 50vw;bottom:3px;transform: translate(-50%, 0);
}
/*展示按钮*/
@keyframes guanBiXiaDan {
  0%{
    width: 90%;background-color: #F5F5F5;border-radius: 10px;padding: 16px 10px;left: 50vw;bottom:50%;transform: translate(-50%, 50%);
  }
  100%{
    width: 80%;background-color: #007539;border-radius: 20px;color: white;padding: 6px;font-weight: bold;left: 50vw;bottom:3px;transform: translate(-50%, 0);

  }
}
/*展示表单*/
@keyframes  xiaDan{
  0%{
    width: 80%;background-color: #007539;border-radius: 20px;color: white;padding: 6px;font-weight: bold;left: 50vw;bottom:3px;transform: translate(-50%, 0);
  }

  100% {
    width: 90%;background-color: #F5F5F5;border-radius: 10px;padding: 16px 10px;left: 50vw;bottom:50%;transform: translate(-50%, 50%);

  }
}
.inputWaiKe{
  width: 100%;overflow: hidden;margin-top: 16px
}
.inputTitle{
  float: left;
  width: 30%;
  height: 30px;
  text-align: left;
  line-height: 30px;
  padding-right: 10px;
  text-indent: 13px;
}
.inputValue{
  float: left;width: 70%;text-align: left
}
</style>