import service from "../gwRequest"

export function add(query) {
    return service({
        method: "POST",
        url: "/CIntentionPlaceAnOrder/add",
        data: query,
        isNotToken:false
    });
}
