import gwService from "../gwRequest"

export function getTotal(query) {//用户获取已提现金额
    return gwService({
        method: "POST",
        url: "/eWxGzQrCodeShareTransferOfFunds/user/get/total",
        data: query,
        isNotToken:true
    });
}

export function withdrawalRetry(query) {//用户获取已提现金额
    return gwService({
        method: "POST",
        url: "/eWxGzQrCodeShareTransferOfFunds/withdrawal/retry",
        data: query,
        isNotToken:true
    });
}
